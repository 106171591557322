<template>
  <NuxtLink
    v-if="to"
    :to="to"
    v-bind="$attrs"
    :class="variant"
    :target="
      target || (typeof to === 'string' && $isExternalUrl(to))
        ? '_blank'
        : '_self'
    "
  >
    <Component :is="iconComponent" v-if="icon" :class="iconClasses" />
    <slot />
  </NuxtLink>
  <button v-else v-bind="$attrs" :class="variant" type="button">
    <Component :is="iconComponent" v-if="icon" :class="iconClasses" />
    <slot />
  </button>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    to?: string | object | null
    target?: string | null
    icon?: string | null
    iconClasses?: string
    underline?: boolean
    size?: 'xs' | 'sm' | 'base' | 'lg'
  }>(),
  {
    to: null,
    target: null,
    icon: null,
    iconClasses: 'w-5 mr-3',
    size: 'base',
    underline: true,
  }
)

const variant = computed(() => {
  const classes = [
    'flex items-center rounded-sm text-gray-500 transition duration-200 hover:text-gray-400',
  ]

  if (props.underline) {
    classes.push('underline')
  }

  if (props.size === 'xs') {
    classes.push('text-xs font-medium')
  }

  if (props.size === 'sm') {
    classes.push('text-xs font-bold')
  }

  if (props.size === 'base') {
    classes.push('text-sm font-bold')
  }

  if (props.size === 'lg') {
    classes.push('text-base font-bold')
  }

  return classes
})

const iconComponent = computed(() => {
  if (props.icon) {
    return resolveComponent('icon-' + props.icon)
  }
  return null
})
</script>
